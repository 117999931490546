import React from 'react';
import Layout from "../components/layout";
import Subscribe from '../components/subscribe/subscribe';
// import styled from "styled-components";
import { Helmet } from 'react-helmet';
import NewsletterFeedback from '../components/NewsletterFeedback/NewsletterFeedback';

const NewsletterPage = () => {

    return (
        <Layout>
            <Helmet>
                <title>Managers Weekly Newsletter</title>
                <meta name="description" content="ManagersApp Weekly Newsletter"></meta>
            </Helmet>
            <div className={"page-header home"}>
                <h1 style={{ color: "#3d84a8", fontWeight: "normal"}}>Managers Weekly Newsletter</h1>
                <h3 style={{ paddingLeft: "20%", paddingRight: "20%", fontSize: "22px" }}>
                    Get Handpicked Content About Managing Technical Talent Delivered to Your Inbox Weekly.
                </h3>
            </div>
            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col-12 first"}>
                    </div>
                </div>
                <div className={"targets"}>
                    <div className={"title"}>
                        <h1>What Our Readers Say</h1>
                    </div>
                    <NewsletterFeedback />
                </div>
                <div className={"container"}>
                    <div className={"features"}>
                        <div className={"feature__item"}>
                            <div className={"row"}>
                                <div className={"col-12 first"}>
                                    <Subscribe
                                        // title="Hand-picked Content For Leaders Who Manage Technical Talent"
                                        buttonText="Subscribe"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default NewsletterPage